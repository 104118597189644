import { createRouter, createWebHistory } from 'vue-router'

import APIservices from '../services/api'

import CreateAccount from '../views/CreateAccount.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import RecoverPassword from '../views/RecoverPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import UserProfile from '../views/UserProfile.vue'
import UserAccount from '../views/UserAccount.vue'
import UserCircuits from '../views/UserCircuits.vue'
import UserGames from '../views/UserGames.vue'
import UserPlay from '../views/UserPlay'
import UserRanking from '../views/UserRanking.vue'

import Play from '../views/Play.vue'
import CardIda from '../views/CardIda.vue'
import ResumenIda from '../views/CardResumenIda'
import CardVuelta from '../views/CardVuelta.vue'
import ResumenTotal from '../views/CardResumenTotal'

import PrivacyPolicy from '../views/PrivacyPolicy'
import TermsService from '../views/TermsService'
import GoogleLogin from '../views/GoogleLogin.vue'
import FacebookLogin from '../views/FacebookLogin.vue'


const routes = [
  { 
    path: '/create-account',
    name: 'CreateAccount',
    component: CreateAccount
  },
  { 
    path: '/',
    name: 'Home',
    component: Home
  },
  { 
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter(to, from, next) {
      if(localStorage.getItem('token')) {
        next({ path: '/user-profile/play' })
      }
      next()
    }
  },
  { 
    path: '/recover-password',
    name: 'RecoverPassword',
    component: RecoverPassword
  },
  { 
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserProfile,
    children: [
      { path:'user-games', component: UserGames },
      { path:'account', component: UserAccount },
      { path:'circuits', component: UserCircuits },
      { path:'games', component: UserGames },
      { path:'play', component: UserPlay },
      { path:'ranking', component: UserRanking },
    ],
    beforeEnter(to, from, next) {
      if(!localStorage.getItem('token')) {
        next({ name: 'Login' })
      } else if (localStorage.getItem('game-id') && (from.name === 'CardVuelta' || from.name === 'CardIda')) {
        APIservices.deleteGame()
          .then(()=> {
            localStorage.removeItem('game-id')
            localStorage.removeItem('court-id')
            localStorage.removeItem('handicap')
          })
          .catch(err => console.log(err))
      }
      next()
    }
  },
  {
    path: '/play',
    name: 'Play',
    component: Play,
    beforeEnter(to, from, next) {
      if(!localStorage.getItem('token')) {
        next({ name: 'Login' })
      } else if (from.path !== '/user-profile/play') {
        next({ path: '/user-profile/play' })
      }
      next()
    }
  },
  {
    path: '/card-ida',
    name: 'CardIda',
    component: CardIda,
    beforeEnter(to, from, next) {
      if(!localStorage.getItem('token')) {
        next({ name: 'Login' })
      } else if(!localStorage.getItem('game-id')) {
        next({ path: 'user-profile/play' })
      }
      next()
    }
  },
  {
    path: '/resumen-ida',
    name: 'ResumenIda',
    component: ResumenIda,
    beforeEnter(to, from, next) {
      if(!localStorage.getItem('token')) {
        next({ name: 'Login' })
      } else if(!localStorage.getItem('game-id')) {
        next({ path: 'user-profile/play' })
      } else if (localStorage.getItem('game-id') && from.name === 'CardVuelta') {
        APIservices.deleteGame()
          .then(() => {
            localStorage.removeItem('game-id')
            localStorage.removeItem('court-id')
            localStorage.removeItem('handicap')
            next({ path: 'user-profile/play' })
          })
          .catch(err => console.log(err))
      }
      next()
    }
  },
  {
    path: '/card-vuelta',
    name: 'CardVuelta',
    component: CardVuelta,
    beforeEnter(to, from, next) {
      if(!localStorage.getItem('token')) {
        next({ name: 'Login' })
      } else if(!localStorage.getItem('game-id')) {
        next({ path: 'user-profile/play' })
      }
      next()
    }
  },
  {
    path: '/resumen-total',
    name: 'ResumenTotal',
    component: ResumenTotal,
    beforeEnter(to, from, next) {
      if(!localStorage.getItem('token')) {
        next({ name: 'Login' })
      } else if(!localStorage.getItem('game-id')) {
        next({ path: 'user-profile/play' })
      }
      next()
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-of-service',
    name: 'TermsService',
    component: TermsService
  },
  {
    path: '/google-login',
    name: 'GoogleLogin',
    component: GoogleLogin
  },
  {
    path: '/facebook-login',
    name: 'FacebookLogin',
    component: FacebookLogin
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
