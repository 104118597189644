<template>
  <div id="user-games">
    <section class="dropdown-container">
      <div class="dropdown-item">
        <button @click="expandPanel" class="dropdown-button">
          <img src="../assets/icons/arrow-down.svg" alt="arrow-down">
          <p class="paragraph">{{ court }}</p>
        </button>
      </div>
      <ul v-show="toggle" class="dropdown-panel">
        <li v-for="(court, idx) in courts" :key="idx" class="panel-item">
          <button style="color: var(--beige)" class="item-button" @click="filterCourt(court.id, court.name)">{{ court.name }}</button>
        </li>
      </ul>
    </section>
    <table class="table-container">
      <tr class="table-head">
        <th style="text-align:left; padding-left:1rem;">{{ $t('tablaFecha') }}</th>
        <th style="text-align:right; padding-right:.5rem;">{{ $t('tablaPuntos') }}</th>
        <th style="text-align:right; padding-right:1rem;">{{ $t('tablaGolpes') }}</th>
      </tr>
      <tr v-for="(game, idx) in games" :key="idx" class="table-body">
        <td class="table-item" style="padding-left:1rem;">{{ dateFormat(game.date) }}</td>
        <td class="table-item" style="text-align:right; padding-right:.5rem;">{{ game.points }}</td>
        <td class="table-item" style="text-align:right; padding-right:1rem;">{{ game.hits }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import APIservices from '../services/api'
import { DateTime } from "luxon"

export default {
  name: 'UserGamePage',
  data () {
    return {
      toggle: false,
      games: [],
      courts: [],
      court: this.$t('circuitos')
    }
  },
  methods: {
    expandPanel () {
      this.toggle = !this.toggle
    },
    dateFormat (date) {
      let dateFromatted = DateTime.fromISO(date).toFormat('dd/LL/yyyy')
      return dateFromatted
    },
    filterCourt(id, name) {
      APIservices.getGames(id)
        .then(response => {
          const data = response.data.filter((game) => {
            return game.user.username === localStorage.getItem('username')
          })
          this.games = data
          this.court = name
          this.toggle = !this.toggle
        })
        .catch(err => console.log(err))
    }
  },
  mounted () {
    APIservices.getCircuits()
      .then(response => {
        this.courts = response.data
        let id = ''
        if(localStorage.getItem('court-id')) {
          id = Number(localStorage.getItem('court-id'))
        } else {
          id = response.data[0].id
        }
        APIservices.getGames(id)
          .then(response => {
            const data = response.data.filter((game) => {
              return game.user.username === localStorage.getItem('username')
            })
            this.games = data
            this.court = data[0].court.name
          })
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  }
}
</script>

<style lang="css" scoped>
#user-games {
  padding-top: 4.5rem;
}

#user-games .dropdown-container .dropdown-item {
  height: 3rem;
  padding: 0 1rem;
}
#user-games .dropdown-container .dropdown-panel {
  list-style: none;
}
#user-games .dropdown-container .dropdown-panel .panel-item {
  padding: 0 1rem;
  background-color: var(--verdeBosque);
  color: var(--beige);
  height: 3rem;
  border-bottom: 1px solid var(--beige);
  display: flex;
  align-items: center;
}
#user-games .dropdown-container .dropdown-panel .panel-item .item-button {
  color: var(--beige);
  font-size: 1rem;
  border: none;
  width: 100%;
  padding: .5rem;
  background-color: transparent;
  text-align: left;
}
#user-games .dropdown-container .dropdown-item .dropdown-button {
  height: 3rem;
  display: flex;
  align-items: center;
  border: none;
  color: var(--verdeBosque);
  background-color: transparent;
  width: 100%;
}
#user-games .table-container {
  width: 100%;
  border-spacing: 0;
}
#user-games .table-container .table-head {
  height: 3rem;
  color: var(--beige);
  background-color:var(--rojoTeja);
}
#user-games .table-container .table-body {
  height: 3rem;
  color: var(--verdeBosque);
}
#user-games .table-container .table-body .table-item {
  border-bottom: 1px solid var(--verdeBosque);
}

</style>