<template>
  <div id="circuitos">
    <div class="main-container">
      <!--<section v-for="(circuit, idx) in circuits" :key="idx" class="card-container">
        <h1>{{ circuit.name }}</h1>
        <img :src="`${base_url}${circuit.info.formats.thumbnail.url}`" alt="circuito">
      </section>-->
      <section class="card-container">
        <img src="../assets/circutio.png" alt="circuito">
      </section>
    </div>
  </div>
</template>

<script>
// import APIservices from '../services/api'

export default {
  name: 'CircuitsPage',

  data () {
    return {
      base_url: process.env.VUE_APP_BASE_URL_BACK,
      circuits: []
    }
  }

  // mounted () {
  //   APIservices.getCircuits()
  //     .then(response => {
  //       this.circuits = response.data
  //     })
  //     .catch(err => console.log(err))
  // }
}
</script>

<style lang="css">
#circuitos {
  padding-top: 4.5rem;
}
#circuitos .main-container {
  padding: 0 1rem;
}

#circuitos .main-container .card-container img {
  width: 100%;
}
</style>