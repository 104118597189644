<template>
  <div id="play">
    <section>
      <h1 style="margin-bottom:0.5rem; color: var(--beige);" class="header-1">{{ $t('handicapTitulo')}}</h1>
      <form @submit.prevent="sendHandicap">
        <article class="form-input">
          <input style="margin-bottom:0.5rem;" class="input-normal" type="number" placeholder="Handicap*" v-model="handicap">
          <div class="warning-text" v-for="error of v$.handicap.$errors" :key="error.$uid">
            <div v-if="v$.handicap.$error" class="error-msg">{{ error.$message }}</div>
          </div>
          <div v-if="errorCredentials" class="warning-text error-msg">
            {{ $t('formularioWarning') }}
          </div>
        </article>
        <article style="text-align:center">
          <input class="input-submit-beige" type="submit" :value="$t('jugar')">
        </article>
      </form>
    </section>
  </div>
</template>

<script>
import APIservices from '../services/api'

// Vuelidate
import useVuelidate from '@vuelidate/core'
import { required, maxValue, helpers } from '@vuelidate/validators'

export default {
  name: 'UserPlay',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      handicap: null,
      errorCredentials: false
    }
  },

  validations () {
    return {
      handicap: { 
        required: helpers.withMessage(this.$t('formularioWarning'),required),
        maxValueValue: helpers.withMessage(
          ({
            $params
          }) => `${this.$t('handicap-warning')} ${$params.max}`,
        maxValue(36)), 
        $autoDirty: true 
      }
    }
  },

  methods: {
    sendHandicap () {
      if (this.handicap === null) {
        this.errorCredentials = true
        setTimeout(() => {
          this.errorCredentials = false
        }, 2000)
      } else if (this.v$.handicap.$error === false) {
        const user = {
          handicap: this.handicap
        }
        APIservices.updateUser(user)
          .then(() => {
            localStorage.setItem('handicap', this.handicap)
            this.$router.push('/play')
          })
          .catch(err => console.log(err))
      }
    }
  }
}
</script>

<style lang="css" scoped>
#play{
  background-image: url(../assets/foto1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

#play section {
  width: 100%;
  margin: 0 auto;
}
</style>