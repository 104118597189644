<template>
  <div id="home">
    <LocaleSwitcherVue />
    <div class="play-wrap">
      <img src="../assets/Logotipo.svg" alt="Salobre Golf" class="logotipo">
      <router-link style="line-height: 3rem;" to="/login" class="normal-button">
          {{ $t('jugar') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import LocaleSwitcherVue from '../components/LocaleSwitcher.vue'

export default {

  name: 'HomePage',

  components: {
    LocaleSwitcherVue
  }
}
</script>

<style lang="css" scoped>
  #home {
    background-image: url(../assets/foto1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
  }
  .play-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .play-wrap .logotipo {
    max-width: 150px;
    margin-bottom: 2rem;
  }
</style>