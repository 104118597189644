export default {
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "agradecimientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for playing. We've sent a gift to your email address."])},
  "already-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already registered"])},
  "anotar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take note"])},
  "aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
  "cambiarContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "circuitos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circuits"])},
  "confirmar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "confirmarContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Confirm password"])},
  "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "continuarRonda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to next round"])},
  "contraseñaAntigua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Old password"])},
  "contraseñaNueva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*New password"])},
  "crearCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are not registered yet, you can create your account by clicking"])},
  "crearCuentaConProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can login with:"])},
  "cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The word is not correct"])},
  "delete-instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write 'ERASE' to delete your account"])},
  "eliminar-partida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you press accept, you will delete your game"])},
  "eliminarCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Account"])},
  "email-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be a valid email address"])},
  "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "error-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email and/or password"])},
  "filtroAño": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "filtroMes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "filtroSemana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "filtrodia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "finalizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalize"])},
  "finalizarJuego": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End game"])},
  "formularioWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Required Field"])},
  "handicap-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The max value is"])},
  "handicapTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your handicap"])},
  "hoyo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hole"])},
  "iniciarSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
  "inputContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Password"])},
  "inputEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Email"])},
  "inputUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Username"])},
  "inputNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Name"])},
  "instruccionesDeshacer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action can not be undone."])},
  "instruccionesEliminarCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can delete your account and all your data by clicking"])},
  "instruccionesRecuperarContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will send a message to your email so you can recover your account."])},
  "instruccionesResetearContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your new password."])},
  "introduceTuEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email"])},
  "jugar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
  "nuevoUsuario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Player"])},
  "pais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "partidas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
  "password-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must have a min length of"])},
  "politicaDePrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "privacy-policy-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
  "terms-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms of service"])},
  "terms-service-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of service"])},
  "y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and accept the"])},
  "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You want to receive more information about our products / services, events, news and offers."])},
  "privacy-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must read and accept our privacy policy before registering"])},
  "ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking"])},
  "recordarContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not remember your password, you can recover it by pressing"])},
  "recuperarContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover Password"])},
  "required-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name, email and password are required fields"])},
  "resultado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
  "salir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
  "samepass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must have the same value"])},
  "seleccionaTuPais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your country"])},
  "tablaFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "tablaGolpes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hits"])},
  "tablaPuntos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points"])},
  "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone with country code"])},
  "rondasTotales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Round"])}
}