<template>
  <div id="privacy-policy">
    <div class="page-container">
      <div class="markdown paragraph" v-html="terms"></div>
    </div>
  </div>
</template>

<script>
import APIservices from '../services/api'

export default {
  name: 'TermsService',

  data () {
    return {
      terms: null
    }
  },

  mounted () {
    if (this.$i18n.locale === 'es') {
      APIservices.terms()
      .then(response => {
        this.terms = response.data.text
      })
      .catch(err => console.log(err))
    } else {
      APIservices.termsEn()
        .then(response => {
          this.terms = response.data.text
        })
        .catch(err => console.log(err))
    }
  },

}
</script>

<style lang="css" scoped>
#privacy-policy {
  padding: 2rem 0;
}

#privacy-policy .page-container {
  padding: 0 1rem;
}
</style>