<template>
  <div id="resumen-ida">
    <div v-show="toggleModal" class="modal-container">
      <div class="modal">
        <p class="paragraph" style="margin-bottom:.5rem">{{ $t('agradecimientos') }}</p>
        <button @click="finalizeGame" class="confirm-button">{{ $t('finalizar') }}</button>
      </div>
    </div>
    <div class="card-container">
      <div id="card" class="card">
        <section class="card-header">
          <img class="logotipo" src="@/assets/Logotipo.svg" alt="salobre golf logo">
          <article>
            <p style="color:var(--beige)" class="paragraph">{{ username }}</p>
            <p style="color:var(--beige)" class="paragraph">Handicap: {{ handicap }}</p>
            <p style="color:var(--beige)" class="paragraph">1º Ronda</p>
          </article>
        </section>
        <section class="card-body">
          <table class="table-container">
            <tr>
              <th class="small-text" style="text-align:left;">{{ $t('hoyo') }}</th>
              <th class="small-text" v-for="(hole, idx) in result" :key="idx">{{ hole.hole }}</th>
              <th class="small-text" style="background-color:var(--rosa);">Pt</th>
            </tr>
            <tr>
              <th class="small-text" style="text-align:left;">Handicap</th>
              <th class="small-text" v-for="(hole, idx) in result" :key="idx">{{ hole.handicap }}</th>
              <th class="small-text" style="background-color:var(--rosa);"></th>
            </tr>
            <tr>
              <th class="small-text" style="text-align:left;">Par</th>
              <th class="small-text" v-for="(hole, idx) in result" :key="idx">{{ hole.par }}</th>
              <th class="small-text" style="background-color:var(--rosa);"></th>
            </tr>
            <tr>
              <th class="small-text" style="text-align:left;">{{ $t('resultado')}}</th>
              <th class="small-text" v-for="(hole, idx) in result" :key="idx">{{ hole.result }}</th>
              <th class="small-text" style="background-color:var(--rosa);">{{ hits }}</th>
            </tr>
            <tr>
              <th class="small-text" style="text-align:left; width: 30%;">Points</th>
              <th class="small-text" v-for="(hole, idx) in result" :key="idx">{{ hole.points_result }}</th>
              <th class="small-text" style="background-color:var(--rosa);">{{ points }}</th>
            </tr>
          </table>
        </section>
      </div>
      
      <button @click="nextRound" class="confirm-button">{{ $t('continuarRonda') }}</button>
    </div>
    <button @click="finalizeGame" class="outlined-button">{{$t('finalizarJuego')}}</button>
  </div>
</template>

<script>
import APIservices from '../services/api'
// import domtoimage from 'dom-to-image'

export default {
  name: 'ResumenIda',
  data () {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
      toggleModal: false,
      username: '',
      handicap: null,
      result: [],
      points: null,
      hits: null
    }
  },

  methods: {
    // displayModal () {
    //   this.toggleModal = !this.toggleModal
    // },
    finalizeGame () {
      // this.toggleModal = !this.toggleModal
      // const node = document.getElementById('card');
      // domtoimage.toPng(node)
      //   .then(dataUrl => {
      //     const data = {
      //       dataUrl,
      //       url: this.base_url,
      //       lang: this.$i18n.locale
      //     }
      //     APIservices.sendPromotion(data)
      //       .then(() => {
      //         this.$router.push('/user-profile/games')
      //       })
      //       .catch(err => console.log(err))
      //   })
      //   .catch(err => console.log(err))
      // Borrar cuando se aclare el tema de promociones
      this.$router.push('/user-profile/games')
    },
    nextRound () {
      this.$router.push('/card-vuelta')
    }
  },

  mounted () {
    this.username = localStorage.getItem('username')
    this.handicap = localStorage.getItem('handicap')

    APIservices.getOneGame(localStorage.getItem('game-id'))
      .then(response => {
        this.result = response.data.card
        this.points = response.data.points
        this.hits = response.data.hits
      })
      .catch(err => console.log(err))
    
  }
}
</script>

<style lang="css" scoped>
#resumen-ida {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  padding: 1rem 0;
}
#resumen-ida .card-container {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#resumen-ida .card-container .card {
  margin-bottom: 2rem;
  box-shadow: 0px 0px 16px #D9E0D8;
  background-color: var(--beige);
  border-radius: 16px;
  width: 100%;
}

#resumen-ida .card-container .card .card-header {
  background-color: var(--verdeBosque);
  display: flex;
  align-items: flex-end;
  padding: 1rem;
  border-radius: 1rem 1rem 0px 0px;
}
#resumen-ida .card-container .card .card-header .logotipo {
  width: 100%;
  max-width: 4rem;
  margin-right: 1rem;
}
#resumen-ida .card-container .card .card-body {
  padding: 1rem;
}

#resumen-ida .card-container .card .card-body .table-container {
  width: 100%;
  border-spacing: 0;
  margin-bottom: 1rem;
}

#resumen-ida .card-container .card .card-body .table-container tr th {
  padding: .5rem 0;
  min-width: 1.25rem;
}

#resumen-ida .card-container .card .card-body .table-container tr th:nth-child(even) {
  background-color: var(--verdeMenta);
}

#resumen-ida .modal-container {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 810px;
  height: 100vh;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(246, 242, 239, 0.90);
}

#resumen-ida .modal-container .modal {
  width: 100%;
  box-shadow: 0px 0px 16px var(--verdeMenta);
  border-radius: 16px;
  background-color: var(--beige);
  padding: 2rem 1rem;
  text-align: center;
}
</style>