<template>
  <nav id="nav-container">
    <button class="locale-button" @click="toggleLocales">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M6.476 6.28571H5.23829L4.76171 5.14286H1.71429L1.23771 6.28571H0L2.61886 0H3.85714L6.476 6.28571ZM3.23771 1.48571L2.19029 4H4.28571L3.23771 1.48571ZM16 5.14285H9.1429V6.28571H16V5.14285ZM9.1429 7.42856H16V8.57142H9.1429V7.42856ZM9.1429 9.71427H12.5715V10.8571H9.1429V9.71427ZM6.85714 10.8571V9.71428H4V8.57142H2.85714V9.71428H0V10.8571H4.69429C4.48424 11.7148 4.04679 12.4998 3.428 13.1297C3.10363 12.7914 2.82558 12.4115 2.60114 12H1.33314C1.62358 12.6974 2.03897 13.3359 2.55886 13.884C1.94633 14.3202 1.27784 14.6719 0.571429 14.9297L0.972571 16C1.8517 15.6798 2.67798 15.2298 3.424 14.6651C4.17332 15.2302 5.00286 15.6801 5.88514 16L6.28571 14.9297C5.57961 14.6724 4.91133 14.3212 4.29886 13.8857C5.10547 13.0517 5.64794 11.9982 5.85829 10.8571H6.85714Z"
          fill="#081C15" />
      </svg>
    </button>
    <nav v-show="localeStatus">
      <div v-for="(locale, idx) in locales" :key="idx">
        <button class="locale-button" @click="switchLang(locale)">
          {{ locale }}
        </button>
      </div>
    </nav>
  </nav>
</template>

<script>
export default {
  name: "LocaleSwitcher",

  data() {
    return {
      locales: ["es", "en"],
      localeStatus: false
    }
  },
  methods: {
    toggleLocales() {
      this.localeStatus = !this.localeStatus
    },
    switchLang(param) {
      this.localeStatus = false
      this.$i18n.locale = param
    },
  }
}
</script>

<style lang="css" scoped>
#nav-container {
  width: 100%;
  max-width: 810px;
  position: fixed;
  padding: 1rem;
  text-align: right;
}

#nav-container .locale-button {
  background-color: var(--beige);
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: var(--verdeBosque);
  border: none;
  border-radius: 99px;
  width: 3rem;
  height: 3rem;
  margin-bottom: .5rem;
}
</style>