<template>
  <div id="login">
    <header>
      <p class="paragraph">{{ $t('crearCuenta') }}
        <router-link to="/create-account" class="paragraph" >{{ $t('aqui') }}.</router-link>
      </p>
    </header>
    <section class="form-section">
      <h1 style="margin-bottom:2rem;" class="header-1">{{ $t('iniciarSesion') }}</h1>
      <form @submit.prevent="login">
        <article class="form-input">
          <input class="input-outlined" type="email" :placeholder="$t('inputEmail')" v-model="emailValue">
          <div class="warning-text" v-for="error of v$.emailValue.$errors" :key="error.$uid">
            <div v-if="v$.emailValue.$error" class="error-msg">{{ error.$message }}</div>
          </div>
        </article>
        <article style="margin-bottom:1rem;">
          <input class="input-outlined" type="password" :placeholder="$t('inputContraseña')" v-model="passwordValue">
          <div class="warning-text" v-for="error of v$.passwordValue.$errors" :key="error.$uid">
            <div v-if="v$.passwordValue.$error" class="error-msg">{{ error.$message }}</div>
          </div>
          <div v-if="errorCredentials" class="warning-text error-msg">
            {{ $t('error-credentials') }}
          </div>
        </article>
        <section style="margin-bottom:1rem;">
          <h2 style="text-align: center; margin-bottom: 1rem;" class="paragraph">{{ $t('crearCuentaConProviders')}}</h2>
          <article>
            <a :href="`${base_url_api}/connect/google`" class="provider-link" style="margin-bottom:.5rem">Google</a>
            <a :href="`${base_url_api}/connect/facebook`" class="provider-link">Facebook</a>
          </article>
        </section>
        <section style="display: flex; align-items: center; flex-direction: column; margin-bottom: 2rem;">
          <vue-recaptcha
            ref="recaptcha"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired" 
            :sitekey="siteKey">
          </vue-recaptcha>
          <div v-if="captchaError" class="warning-text error-msg">
            {{ $t('formularioWarning') }}
          </div>
        </section>
        <section style="display:flex; align-items:center; justify-content:center; padding-bottom: 6rem;" class="form-section">
          <router-link style="margin-right:1rem;" class="normal-link" to="/recover-password">
            {{ $t('recuperarContraseña') }}
          </router-link>
          <input class="input-submit" type="submit" :value="$t('jugar')">
        </section>
      </form>
    </section>
    
  </div>
</template>

<script>
import APIservices from '../services/api'
import { VueRecaptcha } from 'vue-recaptcha'

// Vuelidate
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators'

export default {
  name: 'LoginPage',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      base_url_api: process.env.VUE_APP_BASE_URL_API,
      siteKey: process.env.VUE_APP_SITE_KEY,
      emailValue: '',
      passwordValue: '',
      robot: false,
      captchaError: false,
      captchaToken: null,
      errorCredentials: false
    }
  },

  validations () {
    return {
      emailValue: { 
        required: helpers.withMessage(this.$t('formularioWarning'),required), 
        email: helpers.withMessage(this.$t('email-warning'), email),
        $autoDirty: true
      },
      passwordValue: { 
        required: helpers.withMessage(this.$t('formularioWarning'),required),
        minLengthValue: helpers.withMessage(
          ({
            $params
          }) => `${this.$t('password-warning')} ${$params.min}`,
        minLength(6)),
        $autoDirty: true 
      }
    }
  },

  methods: {
    login () {
      if (this.emailValue === '' || this.passwordValue === '')  {
        this.errorCredentials = true
        setTimeout(() => {
          this.errorCredentials = false
        }, 2000)
        
      } else if (this.robot === false) {
        this.captchaError = true
        setTimeout(() => {
          this.captchaError = false
        }, 2000)
      } else if (this.v$.emailValue.$error === false && this.v$.passwordValue.$error === false  && this.robot === true) {
        const user = {
          identifier: this.emailValue,
          password: this.passwordValue
        }
        APIservices.login(user)
          .then(response => {
            localStorage.setItem('token', response.jwt)
            localStorage.setItem('username', response.user.username)
            localStorage.setItem('id', response.user.id)
            this.$router.push('/user-profile/play')
          })
          .catch(err => {
            console.log(err)
            if (err.code === 'ERR_BAD_REQUEST') {
              this.errorCredentials = true
              setTimeout(() => {
                this.errorCredentials = false
              }, 2000)
            }
          })
      }
    },
    onCaptchaVerified (recaptchaToken) {
      this.robot = true
      this.captchaToken = recaptchaToken
    },
    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    }
  },

  components: {
    VueRecaptcha
  }
}
</script>

<style lang="css" scoped>
header {
  background-color: var(--rosa);
  padding: 0 1rem;
  height: 6rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
header p a {
  color: var(--rojoTeja);
}
.form-section {
  padding: 0 1rem;
  margin-bottom: 1rem;
}
.form-section .form-input {
  margin-bottom: 0.5rem;
}
</style>