<template>
  <div id="app">
  </div>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  max-width: 810px;
  margin: 0 auto;
}
</style>
