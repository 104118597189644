<template>
  <div id="recover-password" style="background-color: var(--verdeBosque);">
    <div class="form-wrap">
      <img src="../assets/Logotipo.svg" alt="Salobre Golf" class="logotipo">
      <h2 style="color: var(--beige); text-align:center; margin-bottom: 1rem;" class="headline-2">{{ $t('instruccionesResetearContraseña') }}</h2>
      <form @submit.prevent="resetPassword" style="text-align:center; width:100%;">
        <article class="form-input">
          <input class="input-outlined" type="password" :placeholder="$t('inputContraseña')" v-model="passwordValue">
          <div class="warning-text" v-for="error of v$.passwordValue.$errors" :key="error.$uid">
            <div v-if="v$.passwordValue.$error" class="error-msg">{{ error.$message }}</div>
          </div>
        </article>
        <article class="form-input">
          <input class="input-outlined" type="password" :placeholder="$t('confirmarContraseña')" v-model="confirmPasswordValue">
          <div class="warning-text" v-for="error of v$.confirmPasswordValue.$errors" :key="error.$uid">
            <div v-if="v$.confirmPasswordValue.$error" class="error-msg">{{ error.$message }}</div>
          </div>
        </article>
        <input class="input-submit-beige" type="submit" :value="$t('enviar')">
      </form>
    </div>
  </div>
</template>

<script>
import APIservices from '../services/api'

// Vuelidate
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs, helpers } from '@vuelidate/validators'

export default {
  name: 'ResetPassword',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      passwordValue: '',
      confirmPasswordValue: ''
    }
  },

  validations () {
    return {
      passwordValue: { 
        required: helpers.withMessage(this.$t('formularioWarning'),required),
        minLengthValue: helpers.withMessage(
          ({
            $params
          }) => `${this.$t('password-warning')} ${$params.min}`,
        minLength(6)), 
        $autoDirty: true
      },
      confirmPasswordValue: { 
        required: helpers.withMessage(this.$t('formularioWarning'),required),
        minLengthValue: helpers.withMessage(
          ({
            $params
          }) => `${this.$t('password-warning')} ${$params.min}`,
        minLength(6)),
        sameAsPassword: helpers.withMessage(this.$t('samepass'), sameAs(this.passwordValue)),
        $autoDirty: true
      }
    }
  },

  methods: {
    resetPassword () {
      if (this.v$.passwordValue.$error === false && this.v$.confirmPasswordValue.$error === false) {
        const user = {
          code: this.$route.query.code,
          password: this.passwordValue,
          passwordConfirmation: this.confirmPasswordValue
        }
        APIservices.resetPassword(user)
          .then(() => {
            this.$router.push('/login')
          })
          .catch(err => console.log(err))
      }
    }
  }
}
</script>

<style lang="css" scoped>
  .form-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 0 1rem;
    color: white;
  }
  .form-wrap .logotipo {
    max-width: 150px;
    margin-bottom: 2rem;
  }
  .form-input {
    margin-bottom: 0.5rem;
  }
</style>