export default {
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
  "agradecimientos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grácias por jugar. Hemos enviado un regalo a tu dirección de correo."])},
  "already-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya está registrado"])},
  "anotar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anotar"])},
  "aqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aquí"])},
  "cambiarContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar Contraseña"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "circuitos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circuitos"])},
  "confirmar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "confirmarContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Confirmar contraseña"])},
  "continuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "continuarRonda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar a siguiente ronda"])},
  "contraseñaAntigua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Contraseña antigua"])},
  "contraseñaNueva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Nueva contraseña"])},
  "crearCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si aún no estás registrado, puedes crear tu cuenta pulsando"])},
  "crearCuentaConProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes iniciar sesión con:"])},
  "cuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
  "delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La palabra no es correcta"])},
  "delete-instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba 'BORRAR' para eliminar su cuenta"])},
  "eliminar-partida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si apreta aceptar, borrará su partida"])},
  "eliminarCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar Cuenta"])},
  "email-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ser un formato de email válido"])},
  "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "error-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revise su email y/o contraseña"])},
  "filtroAño": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año"])},
  "filtroMes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
  "filtroSemana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
  "filtrodia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día"])},
  "finalizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])},
  "finalizarJuego": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar Juego"])},
  "formularioWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Campo Obligatorio"])},
  "handicap-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El valor máximo es"])},
  "handicapTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce tu Handicap"])},
  "hoyo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoyo"])},
  "iniciarSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar Sesión"])},
  "inputContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Contraseña"])},
  "inputEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Email"])},
  "inputUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Nombre de usuario"])},
  "inputNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Nombre"])},
  "instruccionesDeshacer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción no se puede deshacer."])},
  "instruccionesEliminarCuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes eliminar tu cuenta y todos tus datos pulsando"])},
  "instruccionesRecuperarContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviaremos un mensaje a tu correo para que puedas recuperar tu cuenta."])},
  "instruccionesResetearContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce tu nueva contraseña."])},
  "introduceTuEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce tu Email"])},
  "jugar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugar"])},
  "nuevoUsuario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo Usuario"])},
  "pais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
  "partidas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partidas"])},
  "password-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña debe ser de almenos"])},
  "politicaDePrivacidad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
  "privacy-policy-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de privacidad"])},
  "y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y"])},
  "terms-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["términos de servicio"])},
  "terms-service-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos de servicio"])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He leido y aceptado la"])},
  "marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desea recibir más información sobre nuestros productos / servicios, eventos, noticias y ofertas."])},
  "privacy-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe leer y aceptar nustra política de privacidad antes de registrarse"])},
  "ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking"])},
  "recordarContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no recuerdas tu contraseña, puedes recuperarla pulsando"])},
  "recuperarContraseña": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar Contraseña"])},
  "required-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre, email y contraseña son campos obligatorios"])},
  "resultado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado"])},
  "salir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir"])},
  "samepass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las contraseñas deben ser iguales"])},
  "seleccionaTuPais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona tu país"])},
  "tablaFecha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
  "tablaGolpes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golpes"])},
  "tablaPuntos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos"])},
  "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono con el código del país"])},
  "rondasTotales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rondas Totales"])}
}