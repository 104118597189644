<template>
  <div id="ranking">
    <section class="dropdown-container">
      <div class="dropdown-item">
        <button @click="expandPanel" class="dropdown-button">
          <img src="../assets/icons/arrow-down.svg" alt="arrow-down">
          <p class="paragraph">{{ court }}</p>
        </button>
        <div class="filters">
          <button :class="this.day ? 'filter-button-active' : 'filter-button'" @click="dayFilter">
            <p class="paragraph">{{ $t('filtrodia')}}</p>
          </button>
          <button style="margin-left:1rem;" :class="this.week ? 'filter-button-active' : 'filter-button'" @click="weekFilter">
            <p class="paragraph">{{ $t('filtroSemana')}}</p>
          </button>
          <button style="margin-left:1rem;" :class="this.month ? 'filter-button-active' : 'filter-button'" @click="monthFilter">
            <p class="paragraph">{{ $t('filtroMes')}}</p>
          </button>
          <button style="margin-left:1rem;" :class="this.year ? 'filter-button-active' : 'filter-button'" @click="yearFilter">
            <p class="paragraph">{{ $t('filtroAño')}}</p>
          </button>
        </div>
      </div>
      <ul v-show="toggle" class="dropdown-panel">
        <li v-for="(court, idx) in courts" :key="idx" class="panel-item">
          <button style="color: var(--beige)" class="item-button" @click="filterCourt(court.id, court.name)">{{ court.name }}</button>
        </li>
      </ul>
    </section>
    <table v-if="this.filteredRanking.length > 0" class="table-container">
      <tr class="table-head">
        <th style="text-align:left; padding-left:1rem;">{{ username }}</th>
        <th style="text-align:right; padding-right:.5rem;">{{ position }}</th>
        <th style="text-align:right; padding-right:1rem;">{{ points }}</th>
      </tr>
      <tr v-for="(game, idx) in filteredRanking" :key="idx" class="table-body">
        <td class="table-item" style="padding-left:1rem;">
          <img style="width:1rem;" src="../assets/icons/user.svg" alt="user icon">
          {{ game.user.username }}
        </td>
        <td class="table-item" style="text-align:right; padding-right:.5rem;">
          <img style="width:1rem;" src="../assets/icons/ranking-grey.svg" alt="user icon">
          {{ idx+1 }}
        </td>
        <td class="table-item" style="text-align:right; padding-right:.5rem;">
          {{ game.points }}
        </td>
      </tr>
    </table>
    <table v-else-if="today" class="table-container">
      <tr class="table-head">
        <th style="text-align:left; padding-left:1rem;">{{ username }}</th>
        <th style="text-align:right; padding-right:.5rem;">{{ position }}</th>
        <th style="text-align:right; padding-right:1rem;">{{ points }}</th>
      </tr>
      <tr v-for="(game, idx) in today" :key="idx" class="table-body">
        <td class="table-item" style="padding-left:1rem;">
          <img style="width:1rem;" src="../assets/icons/user.svg" alt="user icon">
          {{ game.user.username }}
        </td>
        <td class="table-item" style="text-align:right; padding-right:.5rem;">
          <img style="width:1rem;" src="../assets/icons/ranking-grey.svg" alt="user icon">
          {{ idx+1 }}
        </td>
        <td class="table-item" style="text-align:right; padding-right:.5rem;">
          {{ game.points }}
        </td>
      </tr>
    </table>
    <table v-else class="table-container">
      <tr class="table-head">
        <th style="text-align:left; padding-left:1rem;">{{ username }}</th>
        <th style="text-align:right; padding-right:.5rem;">{{ position }}</th>
        <th style="text-align:right; padding-right:1rem;">{{ points }}</th>
      </tr>
      <tr v-for="(game, idx) in ranking.games" :key="idx" class="table-body">
        <td class="table-item" style="padding-left:1rem;">
          <img style="width:1rem;" src="../assets/icons/user.svg" alt="user icon">
          {{ game.user.username }}
        </td>
        <td class="table-item" style="text-align:right; padding-right:.5rem;">
          <img style="width:1rem;" src="../assets/icons/ranking-grey.svg" alt="user icon">
          {{ idx+1 }}
        </td>
        <td class="table-item" style="text-align:right; padding-right:.5rem;">
          {{ game.points }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import APIservices from '../services/api'
import { DateTime } from "luxon"

export default {
  name: 'UserRankingPage',
  data () {
    return {
      username: '',
      toggle: false,
      ranking: {},
      filteredRanking: [],
      courts: [],
      position: null,
      points: null,
      date: null,
      showDate: null,
      day: false,
      week: false,
      month: false,
      year: false,
      court: this.$t('circuitos'),
      today: false
    }
  },
  methods: {
    expandPanel () {
      this.toggle = !this.toggle
    },
    filterCourt(id, name) {
      APIservices.getRanking(id)
        .then(response => {
          this.ranking = response
          this.position = ''
          this.points = ''
          this.date = ''
          for(let i = 0; i < this.ranking.games.length; i++) {
            if(this.ranking.games[i].user.username === localStorage.getItem('username')) {
              this.position = i+1
              this.points = this.ranking.games[i].points
              this.date = this.ranking.games[i].date
            }
          }
          this.filteredRanking = []
          this.day = false
          this.week = false
          this.month = false
          this.year = true
          this.today = false
          this.court = name
          this.toggle = !this.toggle
        })
        .catch(err => console.log(err))
    },
    dayFilter () {
      this.filteredRanking = []
      const filtered = this.ranking.games.filter((game) => {
        return DateTime.fromISO(game.date).hasSame(DateTime.fromISO(DateTime.now().toString()), 'day')
      })
      this.position = ''
      this.points = ''
      this.today = filtered
      for(let i = 0; i < this.today.length; i++) {
        if(this.today[i].user.username === localStorage.getItem('username')) {
          this.position = i+1
        }
      }
      this.day = true
      this.week = false
      this.month = false
      this.year = false
    },
    weekFilter () {
      const filtered = this.ranking.games.filter((game) => {
        return DateTime.fromISO(game.date).hasSame(DateTime.fromISO(this.date), 'week')
      })
      this.filteredRanking = filtered
      this.today = false
      for(let i = 0; i < this.filteredRanking.length; i++) {
        if(this.filteredRanking[i].user.username === localStorage.getItem('username')) {
          this.position = i+1
        }
      }
      this.day = false
      this.week = true
      this.month = false
      this.year = false
    },
    monthFilter () {
      const filtered = this.ranking.games.filter((game) => {
        return DateTime.fromISO(game.date).hasSame(DateTime.fromISO(this.date), 'month')
      })
      this.filteredRanking = filtered
      this.today = false
      for(let i = 0; i < this.filteredRanking.length; i++) {
        if(this.filteredRanking[i].user.username === localStorage.getItem('username')) {
          this.position = i+1
        }
      }
      this.day = false
      this.week = false
      this.month = true
      this.year = false
    },
    yearFilter () {
      const filtered = this.ranking.games.filter((game) => {
        return DateTime.fromISO(game.date).hasSame(DateTime.fromISO(this.date), 'year')
      })
      this.filteredRanking = filtered
      this.today = false
      for(let i = 0; i < this.filteredRanking.length; i++) {
        if(this.filteredRanking[i].user.username === localStorage.getItem('username')) {
          this.position = i+1
        }
      }
      this.day = false
      this.week = false
      this.month = false
      this.year = true
    }
  },
  mounted () {
    this.username = localStorage.getItem('username')
    APIservices.getCircuits()
      .then(response => {
        this.courts = response.data
        let id = ''
        if(localStorage.getItem('court-id')) {
          id = Number(localStorage.getItem('court-id'))
        } else {
          id = response.data[0].id
        }
        APIservices.getRanking(id)
          .then(response => {
            this.ranking = response
            for(let i = 0; i < this.ranking.games.length; i++) {
              if(this.ranking.games[i].user.username === localStorage.getItem('username')) {
                this.position = i+1
                this.points = this.ranking.games[i].points
                this.date = this.ranking.games[i].date
              }
            }
            this.year = true
            this.court = response.slug
          })
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  }
}
</script>

<style lang="css" scoped>
#ranking {
  padding-top: 4.5rem;
}
#ranking .dropdown-container .dropdown-item {
  height: 3rem;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}
#ranking .dropdown-container .dropdown-item .dropdown-button {
  height: 3rem;
  display: flex;
  align-items: center;
  border: none;
  color: var(--verdeBosque);
  background-color: transparent;
  width: 40%;
}
#ranking .dropdown-container .dropdown-item .filters {
  display: flex;
  align-items: center;
}
#ranking .dropdown-container .dropdown-item .filter-button {
  height: 3rem;
  display: flex;
  align-items: center;
  border: none;
  color: var(--grey);
  background-color: transparent;
}
#ranking .dropdown-container .dropdown-item .filter-button-active {
  height: 3rem;
  display: flex;
  align-items: center;
  border: none;
  color: var(--verdeBosque);
  background-color: transparent;
}
#ranking .dropdown-container .dropdown-panel {
  list-style: none;
}
#ranking .dropdown-container .dropdown-panel .panel-item {
  padding: 0 1rem;
  background-color: var(--verdeBosque);
  color: var(--beige);
  height: 3rem;
  border-bottom: 1px solid var(--beige);
  display: flex;
  align-items: center;
}
#ranking .dropdown-container .dropdown-panel .panel-item .item-button {
  color: var(--beige);
  font-size: 1rem;
  border: none;
  width: 100%;
  padding: .5rem;
  background-color: transparent;
  text-align: left;
}
#ranking .table-container {
  width: 100%;
  border-spacing: 0;
}
#ranking .table-container .table-head {
  height: 3rem;
  color: var(--beige);
  background-color:var(--rojoTeja);
}
#ranking .table-container .table-body {
  height: 3rem;
  color: var(--verdeBosque);
}
#ranking .table-container .table-body .table-item {
  border-bottom: 1px solid var(--verdeBosque);
}
</style>