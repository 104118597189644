<template>
  <div id="user-account">
    <div v-if="deleteModal" class="modal-container">
      <div class="modal">
        <p class="paragraph" style="margin-bottom:.5rem">{{ $t('delete-instructions') }}</p>
        <section style="margin-bottom:2rem;">
          <input class="input-outlined" type="text" v-model="deleteValue">
          <p v-if="deleteFailed" class="warning-text error-msg">{{ $t('delete-error') }}</p>
        </section>
        <button @click="deleteAccount" class="confirm-button" style="margin-right:.5rem">{{ $t('accept') }}</button>
        <button @click="deleteModal = false" class="normal-button">{{ $t('cancel') }}</button>
      </div>
    </div>
    <header style="margin-bottom:2rem;">
      <img src="../assets/foto4.png" alt="header image">
    </header>
    <form @submit.prevent="changePassword" style="margin-bottom:2rem; padding:0 1rem;" class="form-container">
      <article style="margin-bottom:1rem;" class="input-header">
        <h1 class="header-1">{{ $t('cambiarContraseña') }}</h1>
      </article>
      <article class="input-container">
        <input style="margin-bottom:0.5rem;" class="input-outlined" type="password" :placeholder="$t('contraseñaAntigua')" v-model="currentPasswordValue">
        <div class="warning-text" v-for="error of v$.currentPasswordValue.$errors" :key="error.$uid">
          <div v-if="v$.currentPasswordValue.$error" class="error-msg">{{ error.$message }}</div>
        </div>
        <input style="margin-bottom:0.5rem;" class="input-outlined" type="password" :placeholder="$t('contraseñaNueva')" v-model="newPasswordValue">
        <div class="warning-text" v-for="error of v$.newPasswordValue.$errors" :key="error.$uid">
          <div v-if="v$.newPasswordValue.$error" class="error-msg">{{ error.$message }}</div>
        </div>
        <input style="margin-bottom:0.5rem;" class="input-outlined" type="password" :placeholder="$t('confirmarContraseña')" v-model="confirmPasswordValue">
        <div class="warning-text" v-for="error of v$.confirmPasswordValue.$errors" :key="error.$uid">
          <div v-if="v$.confirmPasswordValue.$error" class="error-msg">{{ error.$message }}</div>
        </div>
        <input class="input-submit" type="submit" :value="$t('confirmar')">
      </article>
    </form>
    <section style="padding:0 1rem;" class="form-container">
      <article class="input-header">
        <p style="margin-bottom:2rem;" class="paragraph">{{ $t('recordarContraseña') }}
          <router-link to="/recover-password" class="normal-link-rojo">{{ $t('aqui') }}</router-link>
        </p>
        <hr style="margin-bottom:2rem;">
        <h1 style="margin-bottom:2rem;" class="header-1">{{ $t('eliminarCuenta') }}</h1>
      </article>
      <article style="margin-bottom:4rem;" class="input-body">
        <p class="paragraph">{{ $t('instruccionesEliminarCuenta') }}
        <!-- To DO: Cuando eliminas la cuenta te lleva a la home -->
          <button @click="openDeleteModal" class="normal-link-rojo button">{{ $t('aqui') }}</button>
          <!--<router-link to="#" class="normal-link-rojo">{{ $t('aqui') }}</router-link>-->
        </p>
        <p>{{ $t('instruccionesDeshacer') }}</p>
      </article>
      <article style="padding-bottom:1rem;" class="input-footer">
        <router-link to="/privacy-policy" class="normal-link">
          {{ $t('politicaDePrivacidad') }}
        </router-link>
      </article>
      <article style="padding-bottom:6rem;" class="input-footer">
        <router-link to="/terms-of-service" class="normal-link">
          {{ $t('terms-service-link') }}
        </router-link> 
      </article>
    </section>
  </div>
</template>

<script>
import APIservices from '../services/api'

// Vuelidate
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs, helpers } from '@vuelidate/validators'

export default {
  name: 'UserAccount',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      currentPasswordValue: '',
      newPasswordValue: '',
      confirmPasswordValue: '',
      deleteModal: false,
      deleteValue: '',
      deleteFailed: false
    }
  },

  validations () {
    return {
      currentPasswordValue: { 
        required: helpers.withMessage(this.$t('formularioWarning'),required),
        minLengthValue: helpers.withMessage(
          ({
            $params
          }) => `${this.$t('password-warning')} ${$params.min}`,
        minLength(6)), 
        $autoDirty: true
      },
      newPasswordValue: { 
        required: helpers.withMessage(this.$t('formularioWarning'),required),
        minLengthValue: helpers.withMessage(
          ({
            $params
          }) => `${this.$t('password-warning')} ${$params.min}`,
        minLength(6)), 
        $autoDirty: true
      },
      confirmPasswordValue: { 
        required: helpers.withMessage(this.$t('formularioWarning'),required),
        minLengthValue: helpers.withMessage(
          ({
            $params
          }) => `${this.$t('password-warning')} ${$params.min}`,
        minLength(6)),
        sameAsPassword: helpers.withMessage(this.$t('samepass'), sameAs(this.newPasswordValue)),
        $autoDirty: true
      }
    }
  },

  methods: {
    changePassword () {
      if (this.v$.currentPasswordValue.$error === false && this.v$.newPasswordValue.$error === false && this.v$.confirmPasswordValue.$error === false) {
        const user = {
          currentPassword: this.currentPasswordValue,
          newPassword: this.newPasswordValue,
          confirmNewPassword: this.confirmPasswordValue
        }
        APIservices.changePassword(user)
          .then(() => {
            this.$router.push('/login')
          })
          .catch(err => console.log(err))
      }
    },
    deleteAccount () {
      if (this.deleteValue !== '' && this.$t('delete-instructions').includes(this.deleteValue) === true) {
        APIservices.deleteUser()
          .then(() => {
            localStorage.removeItem('token')
            localStorage.removeItem('id')
            localStorage.removeItem('username')
            this.$router.push('/')
          })
          .catch(err => console.log(err))
      } else {
        this.deleteFailed = true
        setTimeout(() => {
          this.deleteFailed = false
        }, 2000)
      }
    },
    openDeleteModal () {
      this.deleteModal = true
    }
  }
}
</script>

<style lang="css" scoped>
#user-account {
  padding-top: 4.5rem;
  z-index: -1;
}
#user-account header img {
  width: 100%;
}
.form-container {
  text-align: center;
}
.button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

#user-account .modal-container {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 810px;
  height: 100vh;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(246, 242, 239, 0.90);
}

.modal {
  width: 100%;
  box-shadow: 0px 0px 16px var(--verdeMenta);
  border-radius: 16px;
  background-color: var(--beige);
  padding: 2rem 1rem;
  text-align: center;
}

</style>