<template>
  <div id="user-nav">
    <nav>
      <router-link to="/user-profile/play" class="link">
        <div class="icon">
          <img src="../assets/icons/jugar.svg" alt="jugar nueva partida">
          <p class="small-text">{{ $t('jugar') }}</p>
        </div>
      </router-link>
      <router-link to="/user-profile/games" class="link">
        <div class="icon">
          <img src="../assets/icons/partidas.svg" alt="ver partidas jugadas">
          <p class="small-text">{{ $t('partidas') }}</p>
        </div>
      </router-link>
      <router-link to="/user-profile/ranking" class="link">
        <div class="icon">
          <img src="../assets/icons/ranking.svg" alt="ver ranking">
          <p class="small-text">{{ $t('ranking') }}</p>
        </div>
      </router-link>
      <router-link to="/user-profile/circuits" class="link">
        <div class="icon">
          <img src="../assets/icons/circuitos.svg" alt="ver circuitos">
          <p class="small-text">{{ $t('circuitos') }}</p>
        </div>
      </router-link>
      <router-link to="/user-profile/account" class="link">
        <div class="icon">
          <img src="../assets/icons/cuenta.svg" alt="configurar cuenta">
          <p class="small-text">{{ $t('cuenta') }}</p>
        </div>
      </router-link>
    </nav>
    <button @click="logout" class="exit-button">
      <img src="../assets/icons/salir.svg" alt="configurar cuenta">
      <p class="small-text">{{ $t('salir') }}</p>
    </button>
  </div>
</template>

<script>
export default {
  name: 'UserNav',

  methods: {
    logout () {
      localStorage.removeItem('token')
      localStorage.removeItem('id')
      localStorage.removeItem('username')
      this.$router.push('/')
    }
  }
}
</script>

<style lang="css" scoped>
#user-nav {
  position: fixed;
  background-color: var(--beige);
  width: 100%;
  max-width: 810px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.5rem;
}

#user-nav nav{
  display: flex;
}

#user-nav nav .link {
  width: 3.75rem;
  text-align: center;
  color: var(--verdeBosque);
  display: flex;
  flex-direction: column;
}
#user-nav nav .link .icon {
  display: inline-block;
  padding: 1rem 0;
  
}

#user-nav nav .router-link-exact-active {
  border-bottom: 2px solid var(--verdeBosque);
}

#user-nav .exit-button {
  width: 3.75rem;
  border: none;
  color: var(--rojoTeja);
  background-color: var(--beige);
  height: 100%;
}
</style>