import axios from 'axios';
import qs from 'qs';

const API = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export default {
  // USER
  async login(user) {
    const response = await API.post('/auth/local', {
      ...user
    });

    return response.data
  },
  async googleLogin(jwt, access) {
    const response = await API.get('/auth/google/callback', {
      params: {
        id_token: jwt,
        access_token: access
      }
    })

    return response.data
  },
  async facebookLogin(access) {
    const response = await API.get('/auth/facebook/callback', {
      params: {
        access_token: access
      }
    })

    return response.data
  },
  async signup(newUser) {
    const response = await API.post('/auth/local/register', {
      ...newUser
    });
    return response.data
  },
  async signupTech(newUser) {
    const response = await API.post('/users-permissions/register-tech', {
      ...newUser
    })
    return response.data
  },
  async updateUser(user) {
    const response = await API.put(`/users/${localStorage.getItem('id')}`, { ...user },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    return response.data
  },
  async deleteUser() {
    const response = await API.delete(`/users/${localStorage.getItem('id')}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    return response.data
  },
  async forgotPassword(user) {
    const response = await API.post('/auth/forgot-password', {
      ...user
    })
    return response.data
  },
  async resetPassword(user) {
    const response = await API.post('/auth/reset-password', {
      ...user
    })
    return response.data
  },
  async changePassword(user) {
    const response = await API.post('/users-permissions/change-password', { ...user },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    return response.data
  },
  // CIRCUITS
  async getCircuits () {
    const response = await API.get('/courts?populate=*', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    return response.data
  },
  async getOneCircuit (id) {
    const response = await API.get(`/courts/${id}?populate=*`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    return response.data
  },
  // GAMES
  async getGames (court) {
    const query = qs.stringify({
      populate: '*',
      sort: 'date:desc',
      filters: {
        court: {
          id: {
            $eq: court
          }
        }
      }
    }, {
      encodeValuesOnly: true
    })
    const response = await API.get(`/games?${query}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    return response.data
  },
  async getOneGame (id) {
    const response = await API.get(`/games/${id}?populate=*`, 
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    return response.data
  },
  async createGame (id) {
    const date = new Date()
    const response = await API.post('/games', 
    { data: {
      date: date,
      user: localStorage.getItem('id'), 
      court: id 
      } 
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    return response.data
  },
  async updateGame (card) {
    const response = await API.put(`/games/${localStorage.getItem('game-id')}`, {
      data: {
        user: localStorage.getItem('id'),
        card: card
      }
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    return response.data
  },
  async deleteGame () {
    const response = await API.delete(`/games/${localStorage.getItem('game-id')}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    return response.data
  },
  // RANKINGS
  async getRanking (court) {
    const query = qs.stringify({
      filters: {
        court: {
          id: {
            $eq: court
          }
        }
      }
    }, {
      encodeValuesOnly: true
    })
    const response = await API.get(`/standings?${query}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    return response.data
  },
  // PROMOTIONS
  async sendPromotion (data) {
    const response = await API.post('/promotions/send', {
      data: {
        user: localStorage.getItem('id'),
        game: localStorage.getItem('game-id'),
        card: data.dataUrl,
        url: data.url,
        lang: data.lang
      }
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    return response.data
  },
  // PRIVACY POLICY
  async privacyPolicy () {
    const response = await API.get('/privacy-policy')
    return response.data
  },
  async privacyPolicyEn () {
    const response = await API.get('/privacy-policy', { params: { locale: 'en' } })
    return response.data
  },
  // TERMS OF SERVICE
  async terms () {
    const response = await API.get('/terms-of-service')
    return response.data
  },
  async termsEn () {
    const response = await API.get('/terms-of-service', { params: { locale: 'en' } })
    return response.data
  }
};