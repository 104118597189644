<template>
  <div id="provider-login">
    <div class="play-wrap">
      <img src="../assets/Logotipo.svg" alt="Salobre Golf" class="logotipo">
    </div>
  </div>
</template>

<script>
import APIservices from '../services/api'

export default {
  name: 'GoogleLogin',

  methods: {
    googleLogin () {
      APIservices.googleLogin(this.$route.query.id_token, this.$route.query.access_token)
      .then(response => {
        localStorage.setItem('token', response.jwt)
        localStorage.setItem('username', response.user.username)
        localStorage.setItem('id', response.user.id)
        this.$router.push('/user-profile/play')
        APIservices.signupTech(response.user)
          .then()
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
    }
  },

  created () {
    this.googleLogin()
  }

}
</script>

<style lang="css" scoped>
  #provider-login {
    background-color: var(--rosa);
    height: 100vh;
  }
  .play-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .play-wrap .logotipo {
    max-width: 150px;
    margin-bottom: 2rem;
  }
</style>