<template>
  <div id="create-account">
    <header>
      <h1 class="header-1">{{ $t('nuevoUsuario') }}</h1>
    </header>
    <form @submit.prevent="signup">
      <!-- Formulario Normal -->
      <article class="form-input">
        <input class="input-outlined" type="text" :placeholder="$t('inputNombre')" v-model="nameValue">
        <div class="warning-text" v-for="error of v$.nameValue.$errors" :key="error.$uid">
          <div v-if="v$.nameValue.$error" class="error-msg">{{ error.$message }}</div>
        </div>
      </article>
      <article class="form-input">
        <input class="input-outlined" type="text" :placeholder="$t('inputUser')" v-model="usernameValue">
        <div class="warning-text" v-for="error of v$.usernameValue.$errors" :key="error.$uid">
          <div v-if="v$.usernameValue.$error" class="error-msg">{{ error.$message }}</div>
        </div>
      </article>
      <article class="form-input">
        <input class="input-outlined" type="email" :placeholder="$t('inputEmail')" v-model="emailValue">
        <div class="warning-text" v-for="error of v$.emailValue.$errors" :key="error.$uid">
          <div v-if="v$.emailValue.$error" class="error-msg">{{ error.$message }}</div>
        </div>
      </article>
      <article class="form-input">
        <input class="input-outlined" type="password" :placeholder="$t('inputContraseña')" v-model="passwordValue">
        <div class="warning-text" v-for="error of v$.passwordValue.$errors" :key="error.$uid">
          <div v-if="v$.passwordValue.$error" class="error-msg">{{ error.$message }}</div>
        </div>
        <div v-if="errorCredentials" class="warning-text error-msg">
          {{ $t('required-fields') }}
        </div>
      </article>
      <article class="form-input">
        <input class="input-outlined" type="tel" :placeholder="$t('telefono')" v-model="telephoneValue">
      </article>
      <article style="margin-bottom:1rem;">
        <CountryList />
      </article>
      <div v-if="registered" class="warning-text error-msg">
        {{ $t('already-account') }}
      </div>
      <!-- Acceso con Providers -->
      <section style="margin-bottom:1rem;" class="providers-section">
        <h2 style="text-align: center; margin-bottom: 1rem;" class="paragraph">{{ $t('crearCuentaConProviders')}}</h2>
        <a :href="`${base_url_api}/connect/google`" class="provider-link" style="margin-bottom:.5rem; background-color: var(--beige);">Google</a>
        <a :href="`${base_url_api}/connect/facebook`" class="provider-link" style="background-color:var(--beige)">Facebook</a>
     </section>
      <section style="display: flex; align-items: center; flex-direction: column; margin-bottom: 2rem;">
        <vue-recaptcha
          ref="recaptcha"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired" 
          :sitekey="siteKey">
        </vue-recaptcha>
        <div v-if="captchaError" class="warning-text error-msg">
          {{ $t('formularioWarning') }}
        </div>
        <article class="form-input" style="margin-bottom:1rem;">
          <input id="privacy-policy" type="checkbox" v-model="privacyPolicy">
          <label for="privacy-policy">{{ $t('privacy-policy') }} <router-link to="/privacy-policy">{{ $t('privacy-policy-link') }}</router-link> {{ $t('y') }} <router-link to="/terms-of-service">{{ $t('terms-service') }}</router-link></label>
          <div v-if="privacyError" class="warning-text error-msg">
            {{ $t('formularioWarning') }}
          </div>
        </article>
        <article class="form-input" style="margin-bottom:1rem;">
          <input id="marketing" type="checkbox" v-model="marketing">
          <label for="marketing">{{ $t('marketing') }}</label>
        </article>
      </section>
      <section style="display:flex; align-items:center; justify-content:center; padding-bottom:24rem;" class="form-section">
        <input class="input-submit" type="submit" :value="$t('jugar')">
      </section>
    </form>
  </div>
</template>

<script>
import APIservices from '../services/api'
import { VueRecaptcha } from 'vue-recaptcha'
import CountryList from '@/components/CountryList.vue'

// Vuelidate
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators'

export default {
  name: 'CreateAccountPage',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      base_url_api: process.env.VUE_APP_BASE_URL_API,
      siteKey: process.env.VUE_APP_SITE_KEY,
      nameValue: '',
      usernameValue: '',
      emailValue: '',
      passwordValue: '',
      telephoneValue: '',
      countryValue: '',
      privacyPolicy: false,
      marketing: false,
      robot: false,
      captchaError: false,
      privacyError: false,
      captchaToken: null,
      errorCredentials: false,
      registered: false
    }
  },

  validations () {
    return {
      nameValue: { 
        required: helpers.withMessage(this.$t('formularioWarning'),required), 
        $autoDirty: true 
      },
      usernameValue: { 
        required: helpers.withMessage(this.$t('formularioWarning'),required), 
        $autoDirty: true 
      },
      emailValue: { 
        required: helpers.withMessage(this.$t('formularioWarning'),required), 
        email: helpers.withMessage(this.$t('email-warning'), email),
        $autoDirty: true 
      },
      passwordValue: { 
        required: helpers.withMessage(this.$t('formularioWarning'),required),
        minLengthValue: helpers.withMessage(
          ({
            $params
          }) => `${this.$t('password-warning')} ${$params.min}`,
        minLength(6)), 
        $autoDirty: true
      }
    }
  },

  methods: {
    signup () {
      if (this.nameValue === '' || this.usernameValue === '' || this.emailValue === '' || this.passwordValue === '')  {
        this.errorCredentials = true
        setTimeout(() => {
          this.errorCredentials = false
        }, 2000)
        
      } else if (this.privacyPolicy === false) {
        this.privacyError = true
        setTimeout(() => {
          this.privacyError = false
        }, 2000)
      } else if (this.robot === false) {
        this.captchaError = true
        setTimeout(() => {
          this.captchaError = false
        }, 2000)
      } else if (this.v$.nameValue.$error === false && this.v$.usernameValue.$error === false && this.v$.emailValue.$error === false && this.v$.passwordValue.$error === false  && this.robot === true) {
        const user = {
          name: this.nameValue,
          username: this.usernameValue,
          email: this.emailValue,
          password: this.passwordValue,
          telephone: this.telephoneValue,
          nacionality: this.countryValue,
          marketing: this.marketing,
          terms: this.privacyPolicy
        }
        APIservices.signup(user)
          .then(response => {
            localStorage.setItem('token', response.jwt)
            localStorage.setItem('username', response.user.username)
            localStorage.setItem('id', response.user.id)
            this.$router.push('/user-profile/play')
          })
          .catch(err => {
            console.log(err)
            if (err.code === 'ERR_BAD_REQUEST') {
              this.registered = true
              setTimeout(() => {
                this.registered = false
              }, 2000)
            }
          })
        APIservices.signupTech(user)
          .then()
          .catch(err => console.log(err))
      }
    },
    onCaptchaVerified (recaptchaToken) {
      this.robot = true
      this.captchaToken = recaptchaToken
    },
    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    }
  },
  components: { 
    CountryList,
    VueRecaptcha
  },
  mounted () {
    this.emitter.on('country', (country) => {
      this.countryValue = country
    })
  }
}
</script>

<style lang="css" scoped>
#create-account {
  background-color: var(--rosa);
  padding: 0 1rem;
}
#create-account header {
  padding: 4rem 0 1rem 0;
}
form .form-input {
  margin-bottom: 0.5rem;
}
form .providers-section {
  margin-bottom: 1rem;
}
select {
  width: 100%;
  height: 3rem;
  border-radius: 99px;
  border: 1px solid var(--verdeBosque);
  background-color: var(--beige);
  color: var(--verdeBosque);
  font-size: 1rem;
}
</style>