<template>
  <div id="recover-password" style="background-color: var(--verdeBosque);">
    <div class="form-wrap">
      <img src="../assets/Logotipo.svg" alt="Salobre Golf" class="logotipo">
      <h2 style="color: var(--beige); text-align:center; margin-bottom: 1rem;" class="headline-2">{{ $t('instruccionesRecuperarContraseña') }}</h2>
      <form style="text-align:center; width:100%;" @submit.prevent="forgotPassword">
        <input style="margin-bottom:0.5rem;" class="input-outlined" type="email" :placeholder="$t('introduceTuEmail')" v-model="emailValue">
        <div class="warning-text" v-for="error of v$.emailValue.$errors" :key="error.$uid">
          <div v-if="v$.emailValue.$error" class="error-msg">{{ error.$message }}</div>
        </div>
        <input class="input-submit-beige" type="submit" :value="$t('enviar')">
      </form>
    </div>
  </div>
</template>

<script>
import APIservices from '../services/api'

// Vuelidate
import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'

export default {
  name: 'RecoverPassword',

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      emailValue: '',
      emailValidator: false
    }
  },

  validations () {
    return {
      emailValue: { 
        required: helpers.withMessage(this.$t('formularioWarning'),required), 
        email: helpers.withMessage(this.$t('email-warning'), email),
        $autoDirty: true 
      }
    }
  },

  methods: {
    forgotPassword () {
      if (this.v$.emailValue.$error === false) {
        const user = {
          email: this.emailValue
        }
        APIservices.forgotPassword(user)
          .then(() => {
            this.$router.push('/')
          })
          .catch(err => console.log(err))
      }
    }
  }
}
</script>

<style lang="css" scoped>
  .form-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 0 1rem;
  }
  .form-wrap .logotipo {
    max-width: 150px;
    margin-bottom: 2rem;
  }
</style>