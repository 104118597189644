<template>
  <div id="card">
    <div v-if="deleteModal" class="modal-container">
      <div class="modal">
        <p class="paragraph" style="margin-bottom:.5rem">{{ $t('eliminar-partida') }}</p>
        <button @click="deleteGame" class="confirm-button" style="margin-right:.5rem">{{ $t('accept') }}</button>
        <button @click="deleteModal = false" class="normal-button">{{ $t('cancel') }}</button>
      </div>
    </div>
    <div class="card-container">
      <section class="header">
        <button class="exit-button" @click="openDeleteModal">
          <img src="../assets/icons/salir.svg" alt="botón de salir">
          <p class="small-text">{{ $t('salir') }}</p>
        </button>
        <article class="user-information">
          <p class="paragraph">{{ username }}</p>
          <p class="paragraph">Handicap: {{ handicap }}</p>
        </article>
      </section>
      <section class="hole-pannel">
        <article v-for="(hole, idx) in course" :key="idx" class="hole">
          <p :class="idx === 0 ? 'hole-number' : result.length === 0 ? 'hole-number-inactive' : result[idx].result !== null || (idx > 0 && result[idx-1].result !== null) ? 'hole-number' : 'hole-number-inactive'">
            {{ idx + 1 }}
          </p>
          <p v-if="idx === 0" class="hole-points">
            {{ result.length === 0 ? hits : result[idx].result === null ? hits : `${result[idx].result}` }}
          </p>
          <p v-else-if="idx > 0 && idx === holeIdx && result.length > 0" class="hole-points">
            {{ result[idx].result === null ? hits : `${result[idx].result}` }}
          </p>
          <p v-else-if="idx > 0 && holeIdx !== null && idx !== holeIdx && result[idx].result !== null" class="hole-points">
            {{ `${result[idx].result}` }}
          </p>
          <p v-else class="hole-points">
            -
          </p>
        </article>
      </section>
      <section class="card">
        <div v-for="(hole, idx) in course" :key="idx" >
          <div v-if="idx === holeIdx || (idx === 0 && holeIdx === null)">
            <article class="card-item" style="border-bottom: 1px solid var(--verdeMenta);">
              <p class="paragraph">{{ $t('hoyo')}} </p>
              <p class="paragraph">{{ idx + 1 }}</p>
            </article>
            <article class="card-item" style="border-bottom: 1px solid var(--verdeMenta);">
              <p class="paragraph">Par</p>
              <p class="paragraph">{{ hole.par }}</p>
            </article>
            <article class="card-item" style="border-bottom: 1px solid var(--verdeMenta);">
              <p class="paragraph">Handicap</p>
              <p class="paragraph">{{ hole.handicap }}</p>
            </article>
            <article class="card-item-last" style="display:flex; justify-content:space-between">
              <p class="paragraph">{{ $t('resultado') }}</p>
              <p class="paragraph">{{ result.length === 0 ? hits : result[idx].result === null ? hits : `${result[idx].result}` }}</p>
            </article>
          </div>
        </div>
      </section>
    </div>
    <TheKeyboard v-if="toggle === true" :par="par" />
    <div class="button-container">
      <button class="anotar" style="margin-right:1rem" @click="toggle = true">
        <img src="../assets/icons/anotar.svg" alt="anotar icon">
        <p class="small-text">{{ $t('anotar') }}</p>
      </button>
      <button v-if="allowContinue" class="anotar" @click="submitHole(holeIdx)">
        <img src="../assets/icons/continuar.svg" alt="continuar icon">
        <p class="small-text">{{ $t('continuar') }}</p>
      </button>
      <button v-else class="anotar-unactive">
        <img src="../assets/icons/continuar.svg" alt="continuar icon">
        <p class="small-text">{{ $t('continuar') }}</p>
      </button>
    </div>
  </div>
</template>

<script>
import APIservices from '../services/api'

import TheKeyboard from '../components/TheKeyboard.vue'

export default {
  name: 'CardPage',

  data () {
    return {
      username: '',
      handicap: null,
      course: [],
      holeIdx: null,
      firstHole: true,
      hits: "-",
      card: [],
      result: [],
      toggle: false,
      par: null,
      deleteModal: false 
    }
  },

  components: {
    TheKeyboard
  },

  computed: {
    allowContinue () {
      let allowContinue = false
      if (this.hits !== '-' && this.hits !== 0) {
        allowContinue = true
      }
      return allowContinue
    }
  },

  methods: {
    submitHole (idx) {
      this.card[idx === null ? 0 : idx].result = this.hits
      APIservices.updateGame(this.card)
        .then(response => {
          this.result = response.card
          this.holeIdx += 1
          if (this.holeIdx === 9) {
            this.$router.push('/resumen-ida')
          }
          this.par = this.course[this.holeIdx].par
          this.hits = '-'
        })
        .catch(err => console.log(err))
    },
    openDeleteModal () {
      this.deleteModal = true
    },
    deleteGame () {
      APIservices.deleteGame()
        .then(() => {
          localStorage.removeItem('game-id')
          localStorage.removeItem('court-id')
          localStorage.removeItem('handicap')
          this.$router.push('/user-profile/play')
        })
    }
  },

  mounted () {
    this.username = localStorage.getItem('username')
    this.handicap = localStorage.getItem('handicap')

    APIservices.getOneCircuit(localStorage.getItem('court-id'))
      .then(response => {
        const holes = response.data.holes
        for(let i = 0; i < 9; i++) {
          this.course.push(holes[i])
        }
        this.par = this.course[0].par
        this.card = this.course.map((hole, idx) => {
          return {
            hole: `${idx + 1}`,
            par: hole.par,
            handicap: hole.handicap,
            result: null
          }
        })
      })
      .catch(err => console.log(err))

    APIservices.getOneGame(localStorage.getItem('game-id'))
      .then(response => {
        this.result = response.data.card
      })
      .catch(err => console.log(err))

    this.emitter.on('toggleKeyboard', (toggle) => {
      this.toggle = toggle
    })

    this.emitter.on('totalScore', (score) => {
      const totalScore = Number(score)
      this.hits = totalScore
    })
  }
}
</script>

<style lang="css" scoped>
#card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  max-height: -webkit-fill-available;
}
#card .card-container {
  padding: 0 1rem;
}
#card .modal-container {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 810px;
  height: 100vh;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(246, 242, 239, 0.90);
}
.modal {
  width: 100%;
  box-shadow: 0px 0px 16px var(--verdeMenta);
  border-radius: 16px;
  background-color: var(--beige);
  padding: 2rem 1rem;
  text-align: center;
}
#card .card-container .header {
  display: flex;
  justify-content: space-between;
  height: 4.5rem;
  margin-bottom: .5rem;
}
#card .card-container .header .exit-button {
  border: none;
  color: var(--rojoTeja);
  background-color: var(--beige);
  height: 100%;
}
#card .card-container .header .user-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#card .card-container .hole-pannel {
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
}
#card .card-container .hole-pannel .hole {
  filter: drop-shadow(0px 0px 16px var(--verdeMenta));
  border-radius: 99px;
}
#card .card-container .hole-pannel .hole .hole-number {
  background-color: var(--verdeBosque);
  color: var(--beige);
  font-size: 0.75rem;
  line-height: 0.75rem;
  width: 24px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px 99px 0px 0px;
}
#card .card-container .hole-pannel .hole .hole-number-inactive {
  background-color: var(--verdeMenta);
  color: var(--beige);
  font-size: 0.75rem;
  line-height: 0.75rem;
  width: 24px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px 99px 0px 0px;
}
#card .card-container .hole-pannel .hole .hole-points {
  background-color: var(--beige);
  color: var(--verdeBosque);
  font-size: 0.75rem;
  line-height: 0.75rem;
  width: 24px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 99px 99px;
}
#card .card-container .card {
  box-shadow: 0px 0px 16px var(--verdeMenta);
  padding: 1rem;
  border-radius: 16px;
}
#card .card-container .card .card-item {
  display: flex;
  justify-content: space-between;
  padding-bottom:.5rem;
  margin-bottom: .5rem;
}
#card .button-container {
  padding-bottom: 2rem;
  width: 100%;
  text-align: center;
}
#card .button-container .anotar {
  background-color: var(--verdeBosque);
  width: 5rem;
  height: 5rem;
  border-radius: 99px;
  border: none;
  color: var(--beige);
}
#card .button-container .anotar-unactive {
  background-color: var(--verdeMenta);
  width: 5rem;
  height: 5rem;
  border-radius: 99px;
  border: none;
  color: var(--beige);
}
</style>