<template>
  <div id="play">
    <div class="play-wrap">
      <img src="../assets/Logotipo.svg" alt="Salobre Golf" class="logotipo">
      <div v-for="(circuit, idx) in circuits" :key="idx">
        <button style="line-height: 3rem; margin-bottom:1rem; cursor: pointer;" class="normal-button" @click="createGame(circuit.id)">
          {{ circuit.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import APIservices from '../services/api'

export default {
  name: 'PlayPage',

  data () {
    return {
      circuits: []
    }
  },

  methods: {
    createGame (id) {
      APIservices.createGame(id)
        .then((response) => {
          localStorage.setItem('game-id', response.data.id)
          localStorage.setItem('court-id', id)
          this.$router.push('/card-ida')
        })
        .catch(err => console.log(err))
    }
  },

  mounted () {
    APIservices.getCircuits()
      .then(response => {
        this.circuits = response.data
      })
      .catch(err => console.log(err))
  }
}
</script>

<style lang="css" scoped>
  #play {
    background-image: url(../assets/foto4.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
  }
  .play-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .play-wrap .logotipo {
    max-width: 150px;
    margin-bottom: 2rem;
  }
</style>