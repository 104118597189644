<template>
  <select v-model="country" @click="selectCountry">
    <option selected >{{ $t('seleccionaTuPais') }}</option>
    <option>Afghanistan</option>
    <option>Albania</option>
    <option>Algeria</option>
    <option>Andorra</option>
    <option>Angola</option>
    <option>Antigua and Barbuda</option>
    <option>Argentina</option>
    <option>Armenia</option>
    <option>Austria</option>
    <option>Azerbaijan</option>
    <option>Bahrain</option>
    <option>Bangladesh</option>
    <option>Barbados</option>
    <option>Belarus</option>
    <option>Belgium</option>
    <option>Belize</option>
    <option>Benin</option>
    <option>Bhutan</option>
    <option>Bolivia</option>
    <option>Bosnia and Herzegovina</option>
    <option>Botswana</option>
    <option>Brazil</option>
    <option>Brunei</option>
    <option>Bulgaria</option>
    <option>Burkina Faso</option>
    <option>Burundi</option>
    <option>Cabo Verde</option>
    <option>Cambodia</option>
    <option>Cameroon</option>
    <option>Canada</option>
    <option>Central African Republic</option>
    <option>Chad</option>
    <option>Channel Islands</option>
    <option>Chile</option>
    <option>China</option>
    <option>Colombia</option>
    <option>Comoros</option>
    <option>Congo</option>
    <option>Costa Rica</option>
    <option>Côte d\'Ivoire</option>
    <option>Croatia</option>
    <option>Cuba</option>
    <option>Cyprus</option>
    <option>Czech Republic</option>
    <option>Denmark</option>
    <option>Djibouti</option>
    <option>Dominica</option>
    <option>Dominican Republic</option>
    <option>DR Congo</option>
    <option>Ecuador</option>
    <option>Egypt</option>
    <option>El Salvador</option>
    <option>Equatorial Guinea</option>
    <option>Eritrea</option>
    <option>Estonia</option>
    <option>Eswatini</option>
    <option>Ethiopia</option>
    <option>Faeroe Islands</option>
    <option>Finland</option>
    <option>France</option>
    <option>French Guiana</option>
    <option>Gabon</option>
    <option>Gambia</option>
    <option>Georgia</option>
    <option>Germany</option>
    <option>Ghana</option>
    <option>Gibraltar</option>
    <option>Greece</option>
    <option>Grenada</option>
    <option>Guatemala</option>
    <option>Guinea</option>
    <option>Guinea-Bissau</option>
    <option>Guyana</option>
    <option>Haiti</option>
    <option>Holy See</option>
    <option>Honduras</option>
    <option>Hong Kong</option>
    <option>Hungary</option>
    <option>Iceland</option>
    <option>India</option>
    <option>Indonesia</option>
    <option>Iran</option>
    <option>Iraq</option>
    <option>Ireland</option>
    <option>Isle of Man</option>
    <option>Israel</option>
    <option>Italy</option>
    <option>Jamaica</option>
    <option>Japan</option>
    <option>Jordan</option>
    <option>Kazakhstan</option>
    <option>Kenya</option>
    <option>Kuwait</option>
    <option>Kyrgyzstan</option>
    <option>Laos</option>
    <option>Latvia</option>
    <option>Lebanon</option>
    <option>Lesotho</option>
    <option>Liberia</option>
    <option>Libya</option>
    <option>Liechtenstein</option>
    <option>Lithuania</option>
    <option>Luxembourg</option>
    <option>Macao</option>
    <option>Madagascar</option>
    <option>Malawi</option>
    <option>Malaysia</option>
    <option>Maldives</option>
    <option>Mali</option>
    <option>Malta</option>
    <option>Mauritania</option>
    <option>Mauritius</option>
    <option>Mayotte</option>
    <option>Mexico</option>
    <option>Moldova</option>
    <option>Monaco</option>
    <option>Mongolia</option>
    <option>Montenegro</option>
    <option>Morocco</option>
    <option>Mozambique</option>
    <option>Myanmar</option>
    <option>Namibia</option>
    <option>Nepal</option>
    <option>Netherlands</option>
    <option>Nicaragua</option>
    <option>Niger</option>
    <option>Nigeria</option>
    <option>North Korea</option>
    <option>North Macedonia</option>
    <option>Norway</option>
    <option>Oman</option>
    <option>Pakistan</option>
    <option>Panama</option>
    <option>Paraguay</option>
    <option>Peru</option>
    <option>Philippines</option>
    <option>Poland</option>
    <option>Portugal</option>
    <option>Qatar</option>
    <option>Réunion</option>
    <option>Romania</option>
    <option>Russia</option>
    <option>Rwanda</option>
    <option>Saint Helena</option>
    <option>Saint Kitts and Nevis</option>
    <option>Saint Lucia</option>
    <option>Saint Vincent and the Grenadines</option>
    <option>San Marino</option>
    <option>Sao Tome & Principe</option>
    <option>Saudi Arabia</option>
    <option>Senegal</option>
    <option>Serbia</option>
    <option>Seychelles</option>
    <option>Sierra Leone</option>
    <option>Singapore</option>
    <option>Slovakia</option>
    <option>Slovenia</option>
    <option>Somalia</option>
    <option>South Africa</option>
    <option>South Korea</option>
    <option>South Sudan</option>
    <option>Spain</option>
    <option>Sri Lanka</option>
    <option>State of Palestine</option>
    <option>Sudan</option>
    <option>Suriname</option>
    <option>Sweden</option>
    <option>Switzerland</option>
    <option>Syria</option>
    <option>Taiwan</option>
    <option>Tajikistan</option>
    <option>Tanzania</option>
    <option>Thailand</option>
    <option>The Bahamas</option>
    <option>Timor-Leste</option>
    <option>Togo</option>
    <option>Trinidad and Tobago</option>
    <option>Tunisia</option>
    <option>Turkey</option>
    <option>Turkmenistan</option>
    <option>Uganda</option>
    <option>Ukraine</option>
    <option>United Arab Emirates</option>
    <option>United Kingdom</option>
    <option>United States</option>
    <option>Uruguay</option>
    <option>Uzbekistan</option>
    <option>Venezuela</option>
    <option>Vietnam</option>
    <option>Western Sahara</option>
    <option>Yemen</option>
    <option>Zambia</option>
    <option>Zimbabwe</option>
  </select>

</template>

<script>

export default {
  name:'CountryListComponent',

  data () {
    return {
      country: ''
    }
  },

  methods: {
    selectCountry () {
      this.emitter.emit('country', this.country)
    }
  }
}
</script>

<style lang="css" scoped>
  select {
    width: 100%;
    height: 3rem;
    border-radius: 99px;
    border: 1px solid var(--verdeBosque);
    background-color: var(--beige);
    color: var(--verdeBosque);
    font-size: 1rem;
  }
</style>