<template>
  <div id="keyboard">
    <div class="keyboard-container">
      <section class="number-pannel">
        <p v-if="hitValue === 0">{{ hitValue }}</p>
        <p v-else>{{ totalScore }}</p>
      </section>
      <section class="keyboard-pannel">
        <article class="key-wrap">
          <button @click="keySeven" class="key">7
            <p class="small-text" style="color:var(--grey)">{{ calculateHits(7) }}</p>
          </button>
          <button @click="keyEight" class="key">8
            <p class="small-text" style="color:var(--grey)">{{ calculateHits(8) }}</p>
          </button>
          <button @click="keyNine" class="key">9
            <p class="small-text" style="color:var(--grey)">{{ calculateHits(9) }}</p>
          </button>
        </article>
        <article class="key-wrap">
          <button @click="keyFour" class="key">4
            <p class="small-text" style="color:var(--grey)">{{ calculateHits(4) }}</p>
          </button>
          <button @click="keyFive" class="key">5
            <p class="small-text" style="color:var(--grey)">{{ calculateHits(5) }}</p>
          </button>
          <button @click="keySix" class="key">6
            <p class="small-text" style="color:var(--grey)">{{ calculateHits(6) }}</p>
          </button>
        </article>
        <article class="key-wrap">
          <button @click="keyOne" class="key">1
            <!--<p class="small-text" style="color:var(--grey)">{{ calculateHits(1) }}</p>-->
          </button>
          <button @click="keyTwo" class="key">2
            <p class="small-text" style="color:var(--grey)">{{ calculateHits(2) }}</p>
          </button>
          <button @click="keyThree" class="key">3
            <p class="small-text" style="color:var(--grey)">{{ calculateHits(3) }}</p>
          </button>
        </article>
        <article class="key-options">
          <button @click="cancel" class="key" style="background-color:var(--rojoTeja)">C</button>
          <button @click="confirm" class="key" style="background-color:var(--verdeBosque)">Ok</button>
        </article>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hitValue: 0,
      totalScore: '',
      toggle: false
    }
  },

  props: {
    par: {
      type: Number,
    }
  },

  computed: {
  },

  methods: {
    calculateHits (param) {
      let hits = ''
      if (param - this.par === -2 ) {
        hits = 'Eagle'
      } else if (param - this.par === -1 ) {
        hits = 'Birdie'
      } else if (param - this.par === 0 ) {
        hits = 'Par'
      } else if (param - this.par === 1 ) {
        hits = 'Bogey'
      } else if (param - this.par === 2 ) {
        hits = 'Double Bogey'
      }
      return hits
    },
    keyOne () {
      this.hitValue = 1
      this.totalScore += this.hitValue
    },

    keyTwo () {
      this.hitValue = 2
      this.totalScore += this.hitValue
    },
    keyThree () {
      this.hitValue = 3
      this.totalScore += this.hitValue
    },
    keyFour () {
      this.hitValue = 4
      this.totalScore += this.hitValue
    },
    keyFive () {
      this.hitValue = 5
      this.totalScore += this.hitValue
    },
    keySix () {
      this.hitValue = 6
      this.totalScore += this.hitValue
    },
    keySeven () {
      this.hitValue = 7
      this.totalScore += this.hitValue
    },
    keyEight () {
      this.hitValue = 8
      this.totalScore += this.hitValue
    },
    keyNine () {
      this.hitValue = 9
      this.totalScore += this.hitValue
    },
    cancel () {
      this.totalScore = ""
      this.hitValue = 0
    },
    confirm () {
      this.emitter.emit('toggleKeyboard', this.toggle)
      this.emitter.emit('totalScore', this.totalScore)
    }
  }
}
</script>

<style lang="css" scoped>
#keyboard {
  background-color: var(--beige);
  position: fixed;
  z-index: 1;
  width: 100vw;
  max-width: 810px;
  height: 100vh;
  max-height: -webkit-fill-available;
}
#keyboard .keyboard-container {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  height: -webkit-fill-available;
}
#keyboard .keyboard-container .number-pannel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#keyboard .keyboard-container .number-pannel p {
  font-size: 12.5rem;
  color: var(--verdeMenta);
  text-align: center;
}
#keyboard .keyboard-container .keyboard-pannel {
  width: 100%;
}
#keyboard .keyboard-container .keyboard-pannel .key-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: .5rem;
  margin-bottom: .5rem;
}

#keyboard .keyboard-container .keyboard-pannel .key-wrap .key {
  background-color: var(--beige);
  font-size: 1rem;
  color: var(--verdeBosque);
  border: 1px solid var(--verdeMenta);
  height: 3rem;
  width: 100%;
  border-radius: 99px;
}

#keyboard .keyboard-container .keyboard-pannel .key-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: .5rem;
}

#keyboard .keyboard-container .keyboard-pannel .key-options .key {
  font-size: 1rem;
  border: none;
  color: var(--beige);
  height: 3rem;
  width: 100%;
  border-radius: 99px;
}
</style>