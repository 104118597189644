<template>
  <div id="privacy-policy">
    <div class="page-container">
      <div class="markdown paragraph" v-html="privacyPolicy"></div>
    </div>
  </div>
</template>

<script>
import APIservices from '../services/api'

export default {
  name: 'PrivacyPolicy',

  data () {
    return {
      privacyPolicy: null
    }
  },

  mounted () {
    if (this.$i18n.locale === 'es') {
      APIservices.privacyPolicy()
      .then(response => {
        this.privacyPolicy = response.data.text
      })
      .catch(err => console.log(err))
    } else {
      APIservices.privacyPolicyEn()
        .then(response => {
          this.privacyPolicy = response.data.text
        })
        .catch(err => console.log(err))
    }
  },

}
</script>

<style lang="css" scoped>
#privacy-policy {
  padding: 2rem 0;
}

#privacy-policy .page-container {
  padding: 0 1rem;
}
</style>